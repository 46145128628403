// extracted by mini-css-extract-plugin
export var mainLink = "DishCard-module--mainLink--303rQ";
export var mainDiv = "DishCard-module--mainDiv--1IV8Q";
export var mainSlimDiv = "DishCard-module--mainSlimDiv--3dp-H";
export var bottomDiv = "DishCard-module--bottomDiv--1VAj6";
export var bottomInnerDiv = "DishCard-module--bottomInnerDiv--AfaEZ";
export var categorySpan = "DishCard-module--categorySpan--Eud6I";
export var nameSpan = "DishCard-module--nameSpan--1dwhb";
export var headerMainDiv = "DishCard-module--headerMainDiv--3IV8O";
export var headerLeftDiv = "DishCard-module--headerLeftDiv--3jibq";
export var headerRightDiv = "DishCard-module--headerRightDiv--2Kmdb";
export var starImage = "DishCard-module--starImage--2IQ4Q";
export var pShortText = "DishCard-module--pShortText--oZRW0";
export var seeMoreSpan = "DishCard-module--seeMoreSpan--2btje";