import { rightColumnTitle, mainDiv, mainUl, youtubeLi, instagramLi, 
    facebookLi, youtubeSubscribeDiv, youtubeSuscribeText } from './RecipeRightBar.module.css'   
import React from "react"
import Helmet from "react-helmet"
import useWindowSize from "../../utils/useWindowSize";

const RecipeRightBar = () => {
    const { width } = useWindowSize();

    return (
        <div className={mainDiv}>
            <Helmet>
                <script src="https://apis.google.com/js/platform.js"></script>
            </Helmet>
            <span className={rightColumnTitle}>Seguime en las redes</span>
            <ul className={mainUl}>
                <li className={youtubeLi}> 
                    <a href="https://www.youtube.com/FedeCocina" target="_blank" rel="noreferrer noopener nofollow" aria-label="youtube"> 
                        <span> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="20" height="20"> <path d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path> </svg> </span> 
                        <span>54.000+ suscriptores en Youtube</span> 
                    </a>
                </li>
                <li className={instagramLi}>
                    <a href="https://www.instagram.com/fede.cocina.ya" target="_blank" rel="noreferrer noopener nofollow" aria-label="instagram"> 
                        <span> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="20" height="20"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path></svg> </span> 
                        <span>100.000+ seguidores en Instagram</span> 
                    </a>
                </li>
                <li className={facebookLi}> 
                    <a href="https://www.facebook.com/fede.cocina.ya" target="_blank" rel="noreferrer noopener nofollow" aria-label="facebook-f"> 
                        <span> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264 512" width="20" height="20"><path d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229"></path></svg> </span> 
                        <span>220.000+ Seguidores en Facebook</span> 
                    </a>
                </li>
            </ul>
            { width > 800 ? 
                <div className={youtubeSubscribeDiv}>
                    <p className={youtubeSuscribeText}> Seguime en <b>Youtube</b> totalmente gratis y no te pierdas ninguna de mis recetas. 
                    <br></br>¡Hay una nueva todas las semanas!</p>
                    <div className="g-ytsubscribe" data-channelid="UCEcDF1v3ZO5OyvO1cKjjKlQ" data-layout="full" data-count="default"></div>
                </div>
            :
                <></>
            }
        </div>
    )
}

export default RecipeRightBar;