import React from "react"
import Footer from "../components/Footer"
import NavBar from "../components/NavBar"
import { graphql } from 'gatsby'
import { mainDiv, columnsContainer, leftColumn, mainTitle, pText, rightColumn, recipesDiv, topTitleBreadcrumbs, breadcrumbLink, 
    goToCategoryButton} from './todas-las-recetas.module.css'
import { Helmet } from "react-helmet"
import { getImage } from "gatsby-plugin-image"
import DishCard from "../components/HomeDishes/DishCard"
import RecipeRightBar from "../components/RecipeRightBar/RecipeRightBar"
import compareRecipeBySearches from "../utils/sortRecipes";
import { Link } from "gatsby"
import { navigate } from 'gatsby';

const IndexPage = ({data}) => {
  const categories = data.allMysqlAllCategories.nodes;

  const goToCategory = (pageUrl) => {
    navigate(`/recetas/` + pageUrl + '/');
  }

  return (
    <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Las recetas recetas de postres más fáciles y rápidas - Fede Cocina</title>
          <meta name="description" content="Las recetas más fáciles y rapidas para que prepares en tu casa."></meta>
          <html lang="es"/>
          <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8506919019942528" crossorigin="anonymous"></script>
          <script type="application/ld+json">
          {`{
              "@context": "https://schema.org/",
              "@graph": [
              {
                  "@type": "Organization",
                  "@id": "https://fedecocina.net/#organization",
                  "name": "Fede Cocina",
                  "url": "https://fedecocina.net/",
                  "sameAs": [
                  "https://www.facebook.com/fede.cocina.ya",
                  "https://www.instagram.com/fede.cocina.ya",
                  "https://www.youtube.com/FedeCocina"
                  ],
                  "logo": {
                  "@type": "ImageObject",
                  "@id": "https://fedecocina.net/#logo",
                  "inLanguage": "es-AR",
                  "url": "https://fedecocina.net/imagenes/logo.png",
                  "width": 800,
                  "height": 800,
                  "caption": "Fede Cocina"
                  },
                  "image": {
                  "@id": "https://fedecocina.net/#logo"
                  }
              },
              {
                  "@type": "WebSite",
                  "@id": "https://fedecocina.net/#website",
                  "url": "https://fedecocina.net/",
                  "name": "Fede Cocina",
                  "description": "Las recetas más fáciles y rapidas para que prepares en tu casa.",
                  "publisher": {
                  "@id": "https://fedecocina.net/#organization"
                  },
                  "potentialAction": [
                  {
                      "@type": "SearchAction",
                      "target": "https://fedecocina.net/busqueda-receta?search={search_term_string}",
                      "query-input": "required name=search_term_string"
                  }
                  ],
                  "inLanguage": "es-AR"
              },
              {
                  "@type": "BreadcrumbList",
                  "@id": "https://fedecocina.net/todas-las-recetas/#breadcrumb",
                  "itemListElement": [
                    {
                      "@type": "ListItem",
                      "position": 1,
                      "item": {
                        "@type": "WebPage",
                        "@id": "https://fedecocina.net/",
                        "url": "https://fedecocina.net/",
                        "name": "Home"
                      }
                    },
                    {
                      "@type": "ListItem",
                      "position": 2,
                      "item": {
                        "@type": "WebPage",
                        "@id": "https://fedecocina.net/todas-las-recetas/",
                        "url": "https://fedecocina.net/todas-las-recetas/",
                        "name": "Todas las Recetas"
                      }
                    }
                  ]
              }
              ]
          }`}
          </script>
        </Helmet>
      <NavBar></NavBar>
      <div className={mainDiv}>
        <div className={columnsContainer}>
          <div className={leftColumn}>
            <p className={topTitleBreadcrumbs}>
              <Link className={breadcrumbLink} to={`/`}>Inicio</Link>
            </p>
            {
              categories.map((categoryData) => {
                categoryData.categoryRecipes.sort( compareRecipeBySearches );
                return (
                    <>
                      <h1 className={mainTitle} onClick={() => goToCategory(categoryData.pageUrl)}>{categoryData.title}</h1>
                      <div className={pText} dangerouslySetInnerHTML={{__html: categoryData.text}}></div>
                      <div className={recipesDiv}>
                          { categoryData.categoryRecipes.map((item, index) => {
                            if (index > 2) {
                              return;
                            }
                            const image = getImage(item.image.childImageSharp);
                            return (
                                <DishCard key={item.id} pageUrl={item.pageUrl} image={image} name={item.shortName} shortText={item.introText} category={item.category} rate={item.rate} slimMargin={true}></DishCard>
                            )
                            })
                          }
                      </div>
                      <button className={goToCategoryButton} onClick={() => goToCategory(categoryData.pageUrl)}>Ver Todas</button>
                    </>
                )
                })
            }
          </div>
          <div className={rightColumn}>
            <RecipeRightBar></RecipeRightBar>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export const query = graphql`
{
  allMysqlAllCategories {
    nodes {
      title
      text
      pageUrl
      categoryRecipes {
        id
        image {
          childImageSharp {
            gatsbyImageData(width: 400, placeholder: BLURRED)
          }
        }
        shortName
        category
        pageUrl
        introText
        avgMonthlySearches
      }
    }
  }
}
`

export default IndexPage
