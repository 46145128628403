// extracted by mini-css-extract-plugin
export var mainDiv = "todas-las-recetas-module--mainDiv--2Y1l7";
export var columnsContainer = "todas-las-recetas-module--columnsContainer--2sTRM";
export var leftColumn = "todas-las-recetas-module--leftColumn--2oQYT";
export var mainTitle = "todas-las-recetas-module--mainTitle--1H9vT";
export var pText = "todas-las-recetas-module--pText--dR8f8";
export var rightColumn = "todas-las-recetas-module--rightColumn--2qE8e";
export var recipesDiv = "todas-las-recetas-module--recipesDiv--2XyVS";
export var topTitleBreadcrumbs = "todas-las-recetas-module--topTitleBreadcrumbs--3LVtz";
export var breadcrumbLink = "todas-las-recetas-module--breadcrumbLink--2V6Sh";
export var goToCategoryButton = "todas-las-recetas-module--goToCategoryButton--2qP1h";
export var goToHomeMainDiv = "todas-las-recetas-module--goToHomeMainDiv--jFqrF";