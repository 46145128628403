import { mainLink, mainDiv, mainSlimDiv, bottomDiv, categorySpan, nameSpan, bottomInnerDiv, pShortText, seeMoreSpan,
         headerMainDiv, headerLeftDiv, headerRightDiv, starImage} from './DishCard.module.css'
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from "gatsby"

const DishCard = ({pageUrl, name, category, shortText, image, rate, slimMargin}) => {
    return (
        <Link to={`/receta/${pageUrl}/`} className={mainLink}>
          <div className={slimMargin ? mainSlimDiv : mainDiv}>
            <GatsbyImage image={image} alt={name}/>
            <div className={bottomDiv}>
              <div className={bottomInnerDiv}>
                <div className={headerMainDiv}>
                  <div className={headerLeftDiv}>
                    <h3 className={categorySpan}>{category}</h3>
                  </div>
                  <div className={headerRightDiv}>
                    {rate >= 0 ? 
                      [...Array(5)].map((emptyVal, index) => {
                        if (index + 1 <= rate) {
                          return <StaticImage className={starImage} src="../../images/fullStar.png" alt="Estrella Calificacion"/>;
                        } else if (index + 0.5 <= rate) {
                          return <StaticImage className={starImage} src="../../images/halfStar.png" alt="Estrella Calificacion"/>;
                        } else {
                          return <StaticImage className={starImage} src="../../images/emptyStar.png" alt="Estrella Calificacion"/>;
                        }
                        
                      })
                      :
                      <></>
                    }
                  </div>
                </div>
                <h2 className={nameSpan}>{name}</h2>
                <p className={pShortText}>{shortText} <span className={seeMoreSpan}>Ver Receta</span></p>
              </div>
            </div>
          </div>
        </Link>
    )
}

export default DishCard
  